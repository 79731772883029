import {Tour} from "./tour";
import {useTranslation} from "react-i18next";


export function DashboardTour() {
    const {t} = useTranslation();


    const tourSteps = [{
        target: '.tour_dashboard_cards',
        content: t("tour.dashboard.cards")
    }, {
        target: '.tour_dashboard_chart',
        content: t("tour.dashboard.chart"),
    }, {
        target: '.tour_dashboard_validations',
        content: t("tour.dashboard.validation"),
    }, {
        target: '.tour_dashboard_benefits',
        content: t("tour.dashboard.benefits"),
    }
    ];

    return <Tour steps={tourSteps} tourKey='dashboard'/>
}