import {Tour} from "./tour";
import {useTranslation} from "react-i18next";


export function ValidationSummaryTour() {
    const {t} = useTranslation();


    const tourSteps = [{
        target: '.tour-validation-summary-convert',
        content: t("tour.summary.convert_to_oikos"),
        placement: 'left',
    }, {
        target: '.tour-validation-summary-property-summary',
        content: t("tour.summary.summary")
    }, {
        target: '.tour-validation-summary-profitability',
        content: t("tour.summary.profitability")
    }, {
        target: '.tour-validation-summary-mortgage',
        content: t("tour.summary.mortgage")
    }, {
        target: '.tour-validation-summary-renovation',
        content: t("tour.summary.renovation")
    }, {
        target: '.tour-validation-summary-map',
        content: t("tour.summary.map")
    }];

    return <Tour steps={tourSteps} tourKey='validation_summary'/>
}

