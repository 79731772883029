import React, {useEffect, useState} from 'react';
import Joyride, {STATUS} from 'react-joyride';
import {useTheme} from "@mui/material/styles";
import {useProfileDetailer} from "../../services/profile-detailer";
import {useProfileConfigStorer} from "../../services/profile-config-storer";
import {useTranslation} from "react-i18next";

export function Tour({steps, tourKey, disableScrolling = false}) {
    const theme = useTheme();
    const {t} = useTranslation();
    const {data: profileData, refetch: fetchProfileData} = useProfileDetailer(0);
    const {mutate: storeProfileConfig} = useProfileConfigStorer(() => {
        fetchProfileData();
    }, false);
    const [tourState, setTourState] = useState({
        run: false,
        steps: steps,
    });

    useEffect(() => {
        fetchProfileData();
    }, [fetchProfileData]);

    useEffect(() => {
        if (profileData) {
            tourState.run = profileData?.config?.tour?.[tourKey] === "pending";
        }
    }, [profileData]);

    const handleJoyrideCallback = (data) => {
        const {status} = data;
        const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];

        if (finishedStatuses.includes(status)) {
            setTourState({...tourState, run: false});
            if (profileData?.config) {
                const updatedConfig = {
                    config: {
                        ...profileData.config,
                        tour: {
                            ...profileData.config.tour, [tourKey]: 'done',
                        }
                    }
                };
                storeProfileConfig(updatedConfig);
            }
        }
    };

    return <>
        <Joyride
            callback={handleJoyrideCallback}
            continuous
            run={tourState.run}
            showProgress
            showSkipButton
            disableOverlay={true}
            steps={tourState.steps}
            disableScrollParentFix={true}
            disableScrolling={disableScrolling}
            styles={{
                options: {
                    primaryColor: theme.palette.primary.main,
                    textColor: theme.palette.text.primary,
                },
                buttonBack: {
                    color: 'white',
                    backgroundColor: theme.palette.primary.main,
                    fontFamily: theme.typography.fontFamily,
                    borderRadius: '5px',
                },
                buttonNext: {
                    color: 'white',
                    backgroundColor: theme.palette.primary.main,
                    fontFamily: theme.typography.fontFamily,
                },
                buttonSkip: {
                    color: theme.palette.primary.main,
                    fontFamily: theme.typography.fontFamily,
                },
                title: {
                    fontSize: '1.5em',
                    fontFamily: theme.typography.fontFamily,
                },
                content: {
                    fontSize: '1em',
                    fontFamily: theme.typography.fontFamily,
                },
            }}
            locale={{
                back: t("general.back"),
                close: t("general.close"),
                last: t("general.last"),
                next: t("general.next"),
                skip: t("general.skip"),

            }}
        />
    </>;
}