import {MapContainer, Marker, Popup, TileLayer} from 'react-leaflet';
import {BaseCard} from "../../components/cards";


export default function PropertyMap({latitude, longitude}) {

    const position = [latitude, longitude]
    const mapWidth = "100%";

    return (
        <BaseCard sx={{maxHeight: "auto"}}>
            <MapContainer
                center={position}
                style={{
                    borderRadius: "15px", width: mapWidth, height: "300px",
                }}
                zoom={23}
                scrollWheelZoom={true}
                attributionControl={false}
            >
                <TileLayer
                    attribution=""
                    url="http://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}"
                    maxZoom={20}
                    subdomains={["mt0", "mt1", "mt2", "mt3"]}
                />
                <Marker position={position}>
                    <Popup>Ubicación de la propiedad</Popup>
                </Marker>
            </MapContainer>
        </BaseCard>
    );
}