import {Tour} from "./tour";
import {useTranslation} from "react-i18next";


export function ListTour({tourKey}) {
    const {t} = useTranslation();

    const tourSteps = [
        {
            target: '.tour_list_options',
            content: t("tour.list.options"),
        }, {
            target: '.tour_list_search',
            content: t("tour.list.search")
        }
    ];

    return <Tour steps={tourSteps} tourKey={tourKey} disableScrolling={true}/>
}