import {Tour} from "./tour";
import {useTranslation} from "react-i18next";


export function ValidationFinancialTour() {
    const {t} = useTranslation();


    const tourSteps = [{
        target: '.tour-validation-financial-change',
        content: t("tour.validation_financial.change")
    }, {
        target: '.tour-validation-financial-profitability',
        content: t("tour.validation_financial.profitability"),
    }, {
        target: '.tour-validation-financial-edit-price',
        content: t('tour.validation_financial.edit_price')
    }, {
        target: '.tour-validation-financial-cashflow',
        content: t('tour.validation_financial.cashflow')
    }, {
        target: '.tour-validation-financial-metrics',
        content: t('tour.validation_financial.metrics')
    }, {
        target: '.tour-validation-financial-holding-cost',
        content: t('tour.validation_financial.holding_cost')
    }
    ];

    return <Tour steps={tourSteps} tourKey='validation_financial'/>
}