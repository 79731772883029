import {Tour} from "./tour";
import {useTranslation} from "react-i18next";


export function ValidationVisualTour() {
    const {t} = useTranslation();


    const tourSteps = [{
        target: '.tour_visual_cost',
        content: t("tour.visual.cost")
    }, {
        target: '.tour_visual_reform',
        content: t("tour.visual.reforms"),
    }
    ];

    return <Tour steps={tourSteps} tourKey='validation_visual'/>
}