import Typography from "@mui/material/Typography";
import PropertySummaryCard from "../../components/cards/property-summary-card";
import FinancialSummaryCard from "../../components/cards/financial-summary-card";
import {CardContainer, MortgageSummaryCard} from "../../components/cards";
import {useTranslation} from "react-i18next";
import {OptionsBar} from "../../components/settings";
import React from "react";
import Box from "@mui/material/Box";
import {SecondaryButton} from "../../components/buttons";
import {useLocation, useNavigate} from "react-router-dom";
import ROUTES from "../../routes";
import PropertyMap from "./property_map";
import {VisualValidationSummary} from "../../components/cards/visual-validation-summary";
import Comments from "../administrative_validation/comments";
import {ValidationSummaryTour} from "../../components/tour";

export default function ValidationSummary({
                                              propertyData,
                                              financialData,
                                              parameters,
                                              comments,
                                              propertyRenov
                                          }) {
    const validationRequestID = useLocation().state;
    const navigate = useNavigate();
    const {t} = useTranslation();


    const handleConvertValidation = () => {
        navigate(`${ROUTES.properties.add}`, {
            state: {
                validationRequestID,
                parameters: {
                    ...parameters, isMortgageValid: true
                },
                propertyData
            }
        });
    };
    return <>
        <ValidationSummaryTour/>
        <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "center", mt: 1}}>
            <OptionsBar/>

            <Box sx={{mb: 1}} className="tour-validation-summary-convert">
                <SecondaryButton sx={{paddingY: 0}} onClick={handleConvertValidation}>
                    <Typography variant="caption1" noWrap>
                        {t('validation_to_oikos.convert_to_oikos')}
                    </Typography>
                </SecondaryButton>
            </Box>
        </Box>
        <CardContainer>
            <Box className="tour-validation-summary-property-summary">
                <PropertySummaryCard data={propertyData} sx={{flexGrow: 2}}/>
            </Box>
            <Box className="tour-validation-summary-profitability">
                <FinancialSummaryCard data={financialData} sx={{width: "250px"}}/>
            </Box>

            {financialData?.mortgage?.type ? (<Box sx={{flexGrow: 1}}>
                <MortgageSummaryCard data={financialData} sx={{maxWidth: "300px"}}/>
            </Box>) : null}
            <Box className="tour-validation-summary-renovation">
                <VisualValidationSummary propertyRenov={propertyRenov}/>
            </Box>
            <Box sx={{maxWidth: "850px"}}><Comments comments={comments} inSummary/></Box>
        </CardContainer>
        <Box sx={{mt: 3}}></Box>
        <Box className="tour-validation-summary-map">
            <PropertyMap latitude={propertyData?.latitude} longitude={propertyData.longitude}/>
        </Box>
    </>;
}