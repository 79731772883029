import {Tour} from "./tour";
import {useTranslation} from "react-i18next";


export function ValidationAdministrativeTour() {
    const {t} = useTranslation();


    const tourSteps = [{
        target: '.tour-administrative-validation-description',
        content: t("tour.administrative.description"),
        placement: 'center',
    }, {
        target: '.tour-administrative-validation-request',
        content: t("tour.administrative.request")
    }
    ];

    return <Tour steps={tourSteps} tourKey='validation_administrative'/>
}