import Typography from "@mui/material/Typography";
import {Divider, Stack, useTheme} from "@mui/material";
import DataField from "../data-field";
import {FormatCurrency} from "../../utils/format-number";
import {BaseCard} from "./index";
import {useTranslation} from "react-i18next";

export default function MortgageSummaryCard({data = {}, sx}) {
    const theme = useTheme();
    const {t} = useTranslation();

    const mortgage = data?.mortgage;

    const dataFieldProps = {
        labelVariant: "subtitle2", labelColor: "white", valueVariant: "body2", valueColor: "white"
    }

    return (<BaseCard
        className="tour-validation-summary-mortgage"
        sx={{
            ...sx, background: theme.palette.gradient.primary.main, maxWidth: 400, maxHeight: "250px"
        }}
    >
        <Typography variant="h6" color="white">{t('financial.mortgage.mortgage')} </Typography>
        <Stack spacing={2} alignSelf="stretch">
            <DataField
                sx={{alignSelf: "stretch", margin: "auto"}} direction="column"
                label={t('financial.mortgage.quota')} value={FormatCurrency(mortgage?.payment)}
                {...dataFieldProps} valueVariant="h3"/>
            <Divider sx={{borderBlockStyle: "dashed"}}/>
            <Stack direction="row" spacing={4} alignSelf="stretch" justifyContent="space-between" margin="auto">
                <DataField direction="column" label={t('financial.mortgage.years')} value={mortgage?.years}
                           {...dataFieldProps}/>
                <DataField direction="column" label={t('financial.mortgage.type')}
                           value={mortgage?.type === 1 ? t("financial.mortgage.fixed") : t("financial.mortgage.variable")}
                           {...dataFieldProps}/>
            </Stack>
        </Stack>
    </BaseCard>)
}