import AppPage from "../../common/AppPage";
import PageTitle from "../../components/titles";
import ValidationListItem from "./validation-list-item";
import {Stack} from "@mui/material";
import React, {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import {useTranslation} from "react-i18next";
import {useResponsive} from "../../hooks/use-responsive";
import {FiltersProvider} from "../../components/search_filter/context";
import {OptionsBar, useSettingsContext} from "../../components/settings";
import {AddValidationButton, AddValidationFabButton} from "../validation_add";
import CustomFilterAwarePaginator from "../../components/paginator";
import ROUTES from "../../routes";
import {EmptyValidationList} from "../../components/app-status";
import {ValidationItemFiltered, ValidationsSearchFilterBar} from "../../components/search_filter/";
import {useValidationLister} from "../../services/validationRequest/validation-lister";
import {LoadingScreenBar} from "../../components/loading-screen";
import {useProfileDetailer} from "../../services/profile-detailer";
import {useNavigate} from 'react-router-dom';
import RefreshButton from "../../common/refresh-button";
import {ProductUses} from "../../common/product-uses/product-uses";
import {ListTour} from "../../components/tour";

function applySettings(response, settings) {
    const priceToUse = settings?.useWaldenPrice ? "walden" : "market"
    return response.map((i) => {
        return {
            ...i, roi: i.roi[priceToUse], performance: i.performance[priceToUse]
        }
    })
}

export default function ValidationsList() {
    const navigate = useNavigate();
    const {t} = useTranslation();
    const [validationList, setValidationList] = useState([]);
    const [pageItems, setPageItems] = useState([]);
    const smUp = useResponsive('up', 'sm');
    const settings = useSettingsContext();
    const {data: fetchedProfileData, isLoading: isLoadingProfile} = useProfileDetailer(0, (_) => {
    });
    const {data, isLoading: isLoadingList, refetch: refetchList} = useValidationLister();
    const handleItemDeleted = (deletedId) => {
        const newList = validationList.filter(item => item.id !== deletedId);
        setValidationList(newList);
    };
    const lgDown = useResponsive('down', 'lg');

    const handleRefresh = () => {
        refetchList();
    };

    useEffect(() => {
        if (data) {
            setValidationList(applySettings(data, settings));
        }
    }, [data, settings.useWaldenPrice, settings.useMonthlyPeriod, settings.useOptimistProfile]);

    const renderComponent = () => {
        if (!isLoadingProfile && !fetchedProfileData?.investor_profile?.id) {
            navigate(ROUTES.onboarding.profile);
        }
        return (
            <AppPage containerComponent="box" topBarTitle={t("validation_list.title")}>
                <ListTour tourKey={"validation_list"}/>
                <Box sx={{pb: "50px"}}>
                    {!lgDown && (
                        <Stack direction="row" justifyContent={lgDown ? "flex-end" : "space-between"} sx={{mb: '20px'}}>
                            <PageTitle>{t("validation_list.title")}</PageTitle>
                            {smUp ? (
                                <Box>
                                    <AddValidationButton fetchedProfileData={fetchedProfileData}/>
                                </Box>
                            ) : (
                                <AddValidationFabButton fetchedProfileData={fetchedProfileData}/>
                            )}
                        </Stack>
                    )}

                    {lgDown && (
                        <Stack direction="row" justifyContent="flex-end" sx={{mb: '20px'}}>
                            {smUp ? (
                                <Box sx={{display: "flex", justifyContent: "space-between"}}>
                                    <AddValidationButton fetchedProfileData={fetchedProfileData}/>
                                </Box>
                            ) : (
                                <AddValidationFabButton fetchedProfileData={fetchedProfileData}/>
                            )}
                        </Stack>
                    )}
                    <FiltersProvider>
                        <Box sx={{
                            height: "50px",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            mt: 2,
                            mb: 1
                        }}>
                            <Box className="tour_list_options">
                                <OptionsBar/>
                            </Box>
                            <Box className="tour_list_search">
                                <ValidationsSearchFilterBar/>
                            </Box>
                        </Box>
                        {isLoadingList || isLoadingProfile ? (
                            <LoadingScreenBar sx={{maxHeight: 400}}/>
                        ) : validationList.length ? (
                            <>
                                <Box sx={{display: "flex", justifyContent: "space-between", mb: "5px", maxHeight: 35}}>
                                    <RefreshButton handleRefresh={handleRefresh}/>
                                    <ProductUses inValidation/>
                                </Box>
                                <Stack spacing={2} mt={2}>
                                    {pageItems.map((d) => (
                                        <ValidationListItem data={d} key={d.id} onDeleted={handleItemDeleted}/>
                                    ))}
                                </Stack>
                                <Box>
                                    <CustomFilterAwarePaginator
                                        items={validationList}
                                        pageSize={10}
                                        setPageItems={setPageItems}
                                        doFilter={ValidationItemFiltered}
                                    />
                                </Box>
                            </>
                        ) : (
                            <EmptyValidationList
                                imageSrc="https://cdn.walden.ai/media/img/app/blank-state-validation.svg">
                                <AddValidationButton fetchedProfileData={fetchedProfileData} refetch={refetchList}
                                                     demo/>
                            </EmptyValidationList>
                        )}
                    </FiltersProvider>
                </Box>
            </AppPage>
        );
    };

    return renderComponent();
}