import UserAvatar from "../profile/user-avatar";
import React from "react";
import Popover from "@mui/material/Popover";
import MenuItem from "@mui/material/MenuItem";
import {useNavigate} from "react-router-dom";
import ROUTES from "../../routes";

export default function UserAvatarMenu() {
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(prev => prev == null ? event.currentTarget : null);
    }

    const handleClose = () => {
        setAnchorEl(null);
    }

    return <>
        <UserAvatar onClick={handleClick} sx={{
            '&:hover': {
                cursor: "pointer"
            },
        }}/>
        <Popover open={open} anchorEl={anchorEl} onClose={handleClose} anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }} transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        >
            <MenuItem onClick={() => navigate(ROUTES.profile)}>
                Perfil Inversionista
            </MenuItem>
            <MenuItem onClick={() => navigate(ROUTES.logout)}>
                Cerrar sesión
            </MenuItem>
        </Popover>
    </>;
}

