import {Box} from "@mui/material";
import ValidationRequestProduct from "./validation-request-product";
import OikosProduct from "./oikos-product";
import {useUserBalance} from "../../services/user_balance";
import {LoadingScreenBar} from "../../components/loading-screen";

export const ProductUses = ({inOikos, inValidation}) => {
    const {data: userBalanceData, isLoading} = useUserBalance();

    if (isLoading) {
        return <LoadingScreenBar/>;
    }


    const filteredProducts = userBalanceData.user_subscription_balance.filter((product) => {
        if (inOikos && product.product === "oikos") return true;
        if (inValidation && product.product === "validation-request") return true;
        return !inOikos && !inValidation;

    });

    return (
        <Box>
            {filteredProducts.map((product) => (
                product.product === "validation-request" ? (
                    <ValidationRequestProduct key={product.product} product={product}/>
                ) : product.product === "oikos" ? (
                    <OikosProduct key={product.product} product={product}/>
                ) : null
            ))}
        </Box>
    );
};
export default ProductUses;