import axios from 'axios'

const API_URL = process.env.REACT_APP_API

class ApiServiceV2 {
    constructor() {
        this.client = axios.create({
            baseURL: API_URL,
        });
    }

    async invokeWithToken(auth0Context, func) {
        return auth0Context.getAccessTokenSilently().then((token) => {
            const headers = {
                "Content-Type": "application/json", "Authorization": `Bearer ${token}`
            };
            return func({headers});
        });
    }

    async getProfile(auth0Context) {
        return this.invokeWithToken(auth0Context, (config) => {
            return axios.get(`${API_URL}/investor_profile`, config);
        });
    }

    async storeProfile(auth0Context, profile) {
        return this.invokeWithToken(auth0Context, (config) => {
            return axios.post(`${API_URL}/investor_profile`, profile, config);
        });
    }

    async updateValidationRequest(auth0Context, cadastre_id) {
        return this.invokeWithToken(auth0Context, (config) => {
            return axios.patch(`${API_URL}/validation_request`, cadastre_id, config);
        });
    }

    async postValidationRequest(auth0Context, cadastre_id) {
        return this.invokeWithToken(auth0Context, (config) => {
            return axios.put(`${API_URL}/validation_request`, cadastre_id, config);
        });
    }

    //todo: apply react query mutation delete validation
    async deleteValidationRequest(auth0Context, validation_request_id) {
        return this.invokeWithToken(auth0Context, (config) => {
            return axios.delete(`${API_URL}/validation_request?id=${validation_request_id}`, config);
        });
    }


    async listPropertyValidations(auth0Context) {
        return this.invokeWithToken(auth0Context, (config) => {
            return axios.get(`${API_URL}/validation_request`, config)
        })
    }

    async getPropertyValidationDetail(auth0Context, validation_request_id) {
        return this.invokeWithToken(auth0Context, (config) => {
            return axios.get(`${API_URL}/validation_request?id=${validation_request_id}`, config)
        })
    }

    async postNewOikos(auth0Context, request) {
        return this.invokeWithToken(auth0Context, (config) => {
            return axios.put(`${API_URL}/oikos`, request, config);
        });
    }

    async listOikos(auth0Context) {
        return this.invokeWithToken(auth0Context, (config) => {
            return axios.get(`${API_URL}/oikos`, config);
        });
    }

    async oikosDetails(auth0Context, oikosId) {
        return this.invokeWithToken(auth0Context, (config) => {
            return axios.get(`${API_URL}/oikos?id=${oikosId}`, config);
        });
    }

    async deleteOikos(auth0Context, oikosId) {
        return this.invokeWithToken(auth0Context, (config) => {
            return axios.delete(`${API_URL}/oikos?id=${oikosId}`, config);
        });
    }

    async updateOikosParameters(auth0Context, oikos) {
        return this.invokeWithToken(auth0Context, (config) => {
            return axios.patch(`${API_URL}/oikos`, oikos, config);
        });
    }

    async oikosDashboard(auth0Context) {
        return this.invokeWithToken(auth0Context, (config) => {
            return axios.get(`${API_URL}/oikos/dashboard`, config)
        });
    }

    async validationReqDashboard(auth0Context) {
        return this.invokeWithToken(auth0Context, (config) => {
            return axios.get(`${API_URL}/validation_request/dashboard`, config)
        });
    }

    async pondBalance(auth0Context) {
        return this.invokeWithToken(auth0Context, (config) => {
            return axios.get(`${API_URL}/pond_balance`, config)
        });
    }

    async goalsHistory(auth0Context) {
        return this.invokeWithToken(auth0Context, (config) => {
            return axios.get(`${API_URL}/oikos/history`, config)
        });
    }

    async getChecklistById(auth0Context, checklistId) {
        return this.invokeWithToken(auth0Context, (config) => {
            return axios.get(`${API_URL}/checklist?id=${checklistId}`, config);
        });
    }

    async saveChecklist(auth0Context, checklistData) {
        return this.invokeWithToken(auth0Context, (config) => {
            return axios.post(`${API_URL}/checklist`, checklistData, config);
        });
    }

    async convertValidationToOikos(auth0Context, validationData) {
        return this.invokeWithToken(auth0Context, (config) => {
            return axios.post(`${API_URL}/convert`, validationData, config);
        });
    }


    async getPropertyAnalysis(authContext, checklistId) {
        return this.invokeWithToken(authContext, (config) => {
            return axios.get(`${API_URL}/property_analysis/request?id=${checklistId}`, config)
        });
    }

    async getAnalisysPdf(authContext, propertyId) {
        return this.invokeWithToken(authContext, (config) => {
            return axios.get(`${API_URL}/property_analysis/download?id=${propertyId}`, config)
        });
    }



    async requestPropertyAnalysis(auth0Context, validationRequestId) {
        return this.invokeWithToken(auth0Context, (config) => {
            return axios.post(`${API_URL}/request_property_analysis`, validationRequestId, config);
        });
    }

    async requestStripeCustomerSecret(auth0Context, request_obj) {
        return this.invokeWithToken(auth0Context, (config) => {
            return axios.post(`${API_URL}/payment/customer_secret`, request_obj, config);
        });
    }

    async simpleNotEPurchaseUrl(auth0Context, validationRequestId) {
        return this.invokeWithToken(auth0Context, (config) => {
            return axios.post(`${API_URL}/payment/nota_simple_purchase_url`, validationRequestId, config);
        });
    }

    async getUserBalance(authContext) {
        return this.invokeWithToken(authContext, (config) => {
            return axios.get(`${API_URL}/subscription/user_balance`, config);
        });
    }

    async storeConfig(authContext, userConfig) {
        return this.invokeWithToken(authContext, (config) => {
            return axios.post(`${API_URL}/user_config`, userConfig, config);
        });
    }

    async postUserFeedback(authContext, feedback) {
        return this.invokeWithToken(authContext, (config) => {
            return axios.post(`${API_URL}/userfeedback`, feedback, config);
        });
    }

    async requestDemo(authContext) {
        return this.invokeWithToken(authContext, (config) => {
            return axios.get(`${API_URL}/validation_request/demo`, config);
        });
    }

}

const apiService = new ApiServiceV2();

export default ApiServiceV2;
export {apiService};